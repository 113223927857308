

































































































































































































import Vue from 'vue';
import {
  Component,
  Inject, Provide,
  ProvideReactive,
} from 'vue-property-decorator';
import PageHeader1 from '@/components/PageHeader1.vue';
import Page from '@/components/Page.vue';
import { ApiFacade } from '@/services/ApiFacade';
import { Lesson } from '@/models/entities/Lesson';
import ScheduleTime from '@/views/courses/components/ScheduleTime.vue';
import { Bold, Italic, TiptapVuetify } from 'tiptap-vuetify';
import { AppStore } from '@/store/types/AppStore';
import { UserRoleIds } from '@/types/UserRoleIds';
import cloneDeep from 'lodash/cloneDeep';
import dayjs from 'dayjs';
import { Course } from '@/models/entities/Course';
import { LessonStatus, LessonStatusNames, LessonStatusUiExtended } from '@/types/Statuses';
import { BreadcrumbsItem } from '@/types/BreadcrumbsItem';
import { createBreadCrumbsItems } from '@/views/courses/breadcrumbs';
import { Camera } from '@/models/entities/Camera';
import { Polygon } from '@/models/entities/Polygon';
import LessonCamerasList from '@/views/courses/components/LessonCamerasList.vue';
import { User } from '@/models/entities/User';

@Component({
  components: {
    LessonCamerasList,
    ScheduleTime,
    Page,
    PageHeader1,
    TiptapVuetify,
  },
})
export default class LessonView extends Vue {
  isLoading: boolean = false;
  @Inject() apiFacade!: ApiFacade;
  @Inject() appStore!: AppStore;

  lessonId: number = 0;
  courseId: number = 0;

  @ProvideReactive()
  lesson: Lesson | null = null;
  lessonDescription: string = '';
  extensions = [Bold, Italic];

  @ProvideReactive()
  course: Course | null = null;

  isSaving: boolean = false;
  isEditing: boolean = false;
  durationBeforeStart: string = '';
  lessonStatusName: string = '';
  private error: Error | string = '';

  private timeUpdaterTimeout: number | undefined = undefined;

  isConfirmationDialogVisible: boolean = false;
  confirmationDialogContent: string = '';
  confirmationDialogOnConfirmCallback: ((isConfirm: boolean) => void) | null = null;
  LessonStatus = LessonStatus;

  @ProvideReactive()
  polygon: Polygon | null = null;

  @ProvideReactive()
  polygonCameras: Camera[] = [];

  @ProvideReactive()
  currentUserCellLeasesMappedByCellId: { [cellId: number]: true } = {};

  $refs!: {
    camerasList: LessonCamerasList;
  };

  async created() {
    this.lessonId = parseInt(this.$route.params.lessonId, 10);
    this.courseId = parseInt(this.$route.params.courseId, 10);
    await this.loadEvent();
    this.lessonDescription = this.lesson?.description || '';
    this.timeUpdaterTimeout = setInterval(() => {
      this.update();
    }, 60_000);
  }

  onCamerasListRendered(cameras: Camera[]) {
    if (this.$route.name !== 'course-lesson') {
      return;
    }

    const [firstCamera] = cameras;

    if (!firstCamera) {
      return;
    }

    this.$router.push({
      name: 'courses-lesson-camera',
      params: {
        cameraId: String(firstCamera.cameraId),
      },
    });
  }

  // async mounted() {
  // }

  destroyed() {
    clearInterval(this.timeUpdaterTimeout);
  }

  get lessonDescriptionSanitized(): string {
    return this.$sanitize(this.lessonDescription);
  }

  @ProvideReactive()
  get isTeacher(): boolean {
    return (this.currentUser && this.course?.isCourseTeacher(this.currentUser)) || false;
  }

  get isStudent(): boolean {
    return (this.currentUser && this.course?.isCourseStudent(this.currentUser)) || false;
  }

  @ProvideReactive()
  get isAdmin(): boolean {
    return (
      this.currentUser
      && (this.currentUser.isSuperAdmin
        || this.polygon?.isUserAdmin(this.currentUser)
        || this.course?.isCourseAdmin(this.currentUser))
    ) || false;
  }

  updateLessonStatusName() {
    if (!this.lesson) {
      this.lessonStatusName = '';
      return;
    }
    const currDate = new Date();
    if (this.lesson.status !== LessonStatus.active) {
      this.lessonStatusName = LessonStatusNames[this.lesson.status];
    } else if (this.lesson.lessonDateDt < currDate && this.lesson.lessonFinishDt > currDate) {
      this.lessonStatusName = LessonStatusNames[LessonStatusUiExtended.lessonTime];
    } else if (this.lesson.lessonFinishDt < currDate) {
      this.lessonStatusName = LessonStatusNames[LessonStatus.finished];
    } else if (this.lesson.lessonDateDt > currDate) {
      this.lessonStatusName = LessonStatusNames[LessonStatusUiExtended.waitingForStart];
    } else {
      this.lessonStatusName = LessonStatusNames[this.lesson.status];
    }
  }

  updateDurationBeforeStart() {
    if (!this.lesson) {
      return;
    }

    const diff: number = this.lesson.lessonDateDt.getTime() - Date.now();
    let duration = '';
    if (diff > 0) {
      duration = dayjs.duration(diff, 'milliseconds')
        .humanize();
    }

    this.durationBeforeStart = duration;
  }

  @Provide()
  get currentUser(): User | null {
    return this.appStore.getters.profile;
  }

  get isModifyAllowed(): boolean {
    if (!this.lesson || !this.course || !this.appStore.getters.profile) {
      return false;
    }

    const rel = this.course.courseUsers.find((u: Course['courseUsers'][0]) => {
      return u.user.userId === this.appStore.getters.profile?.userId;
    });

    if (!rel) {
      return false;
    }

    return rel.userRole.userRoleId === UserRoleIds.teacher
      || rel.userRole.userRoleId === UserRoleIds.admin;
  }

  get breadcrumbs(): BreadcrumbsItem[] {
    const items = createBreadCrumbsItems();
    if (this.courseId) {
      // items.push({
      //   text: this.course.fullName,
      // });
      items.push({
        text: 'Занятия',
        exact: true,
        to: {
          name: 'course-schedule',
          params: { courseId: String(this.courseId) },
        },
      });
    }

    return items;
  }

  // confirmationDialog()
  showConfirmationDialog(warningText: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.isConfirmationDialogVisible = true;
      this.confirmationDialogContent = warningText;

      this.confirmationDialogOnConfirmCallback = (isConfirm: boolean) => {
        this.isConfirmationDialogVisible = false;
        resolve(isConfirm);
      };
    });
  }

  async handleUpdateLessonStatus(confirmationText: string, newLessonStatus: LessonStatus) {
    if (!this.lesson) {
      return;
    }

    const isConfirm = await this.showConfirmationDialog(confirmationText);
    if (!isConfirm) {
      return;
    }

    try {
      this.isSaving = true;
      const cloneLesson = cloneDeep(this.lesson);
      cloneLesson.status = newLessonStatus;
      this.lesson = await this.apiFacade.updateLesson(cloneLesson);
    } finally {
      this.isSaving = false;
      this.update();
    }
  }

  async handleLessonFinish() {
    // this.showConfirmationDialog();
  }

  async saveDescription() {
    if (!this.lesson) {
      return;
    }

    try {
      this.isSaving = true;
      const clonedLesson: Lesson = cloneDeep<Lesson>(this.lesson);
      clonedLesson.description = this.lessonDescription;
      this.lesson = await this.apiFacade.updateLesson(clonedLesson);
      this.lessonDescription = this.lesson.description;
      this.isEditing = false;
    } catch (err) {
      this.error = err;
    } finally {
      this.isSaving = false;
      this.update();
    }
  }

  update() {
    this.updateDurationBeforeStart();
    this.updateLessonStatusName();
    this.updateCellLeases();
  }

  updateCellLeases() {
    if (!this.polygon || !this.currentUser) {
      return;
    }
    this.apiFacade
      .fetchCellLeasesForPolygon(this.polygon, this.currentUser)
      .then((leases) => {
        const currLeases = (leases || []).reduce((res, lease) => {
          res[lease.cell.cellId] = true;
          return res;
        }, {} as this['currentUserCellLeasesMappedByCellId']);
        this.currentUserCellLeasesMappedByCellId = currLeases;
      });
  }

  async loadEvent() {
    this.isLoading = true;
    try {
      [this.lesson, this.course] = await Promise.all([
        this.apiFacade.fetchLesson(this.lessonId),
        this.apiFacade.fetchCourse(this.courseId),
      ]);
      if (this.course) {
        [this.polygon, this.polygonCameras] = await Promise.all([
          this.apiFacade.fetchPolygonInfo(this.course.polygon.polygonId),
          this.apiFacade.fetchPolygonCameras(this.course.polygon) || [],
        ]);
      }
      this.polygonCameras.sort((a, b) => {
        return a.cameraName > b.cameraName ? 1 : -1;
      });
      this.update();
    } finally {
      this.isLoading = false;
    }
  }
}
